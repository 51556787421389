@font-face {
  font-family: "Founders Grotesk";
  src: url("/fonts/FoundersGroteskWeb-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("/fonts/FoundersGroteskWeb-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "Stag Bold";
  src: url("/fonts/Stag-Bold-Web.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

.container {
    font-family: 'Founders Grotesk', sans-serif;
}

.App {
    text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, h2, h3, h4, h5, h6 {
    padding-top: 1cm;
    font-family: 'Stag Bold', serif;
}

h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 1.6rem;
}

.description img {
    width: 100%;
    object-fit: contain;
    max-height: 10cm;
}
